<template>
  <div class="quantity_report">
    <v-container fluid>
      <v-btn
          v-if="dataLoaded"
          color="danger"
          class="mb-5"
          hide-details
          small
          id="print"
          @click="print"
      >
        {{ $t("Print") }}
      </v-btn>
      <v-btn
          v-if="dataLoaded"
          color="danger"
          class="mb-5"
          hide-details
          small
          style="background:#ea8f8f !important;"
          id="export"
          @click="exportTable"
      >
        تصدير لإكسل
      </v-btn>

      <table ref="exportTable" v-if="dataLoaded">
        <thead>
        <tr>
          <th class="export-table-header">المشتركين لهذا اليوم
            Subscribers for today
          </th>
          <th class="export-table-header"># المشتركين الفعالين
            Active Subscriber
          </th>
          <th class="export-table-header"># الايقافات المؤقتة
            Temp. Subscriber
          </th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td class="export-table-cell">{{ items.subscription_count.all }}</td>
          <td class="export-table-cell">{{ items.subscription_count.active }}</td>
          <td class="export-table-cell">{{ items.subscription_count.stop }}</td>
        </tr>
        <tr>
          <td colspan="3">
            <ReportProteinCarb
                v-for="(item, index) in items.report_protein_carb"
                :key="'division_' + index"
                :division-title="  ' القسم '  + ' Division ' + (index + 1)"
                :proteins="item.proteins"
                :carbs="item.carbs"
            />
          </td>
        </tr>
        <tr>
          <td colspan="3">
            <ReportTable
                v-if="items.protein_carb_out_of_kitchen"
                title="التحويل خارج المطبخ ( Convert to Kitchen ) "
                :headers="['النوع ( Type )', 'العدد ( count )']"
                :data="[
                  ['بروتين (Protein) ', items.protein_carb_out_of_kitchen.protein],
                  ['كاربوهيدرات ( Carb )', items.protein_carb_out_of_kitchen.carb]
                ]"
            />
          </td>
        </tr>
        <tr>
          <td colspan="3">
            <ReportTable
                v-if="items.groups"
                title="تقرير الوصفات (Prescription report)"
                :headers="['  الوصفة Recipe', ' الميزة Advantage', 'العدد ( count )']"
                :data="items.groups"
            />
          </td>
        </tr>
        <tr>
          <td colspan="3">
            <ReportTable
                v-if="items.groups_out_of_kitchen && items.groups_out_of_kitchen.length"
                title=" الأنواع الأخرى ( Other Types ) "
                :headers="['اسم المجموعة Group Name ', 'الكمية  Quantity ']"
                :data="items.groups_out_of_kitchen"
            />
          </td>
        </tr>
        <tr>
          <td colspan="3">
            <ReportTable
                v-if="items.package_details && items.package_details.length"
                title=" ( Numbers report )  تقارير الباقات "
                :headers="['اسم الباقة Package Name ', 'العدد Count ', 'النسبة المئوية Percentage ']"
                :data="items.package_details"
            />
          </td>
        </tr>
        </tbody>
      </table>

      <div v-else>
        <!-- loader here -->
        <div class="loader"></div>
        <h1
            style="
            text-align: center;
            color: blue;
            position: absolute;
            top: 58%;
            left: 35%;
          "
        >
          ... جاري جلب البيانات Fetching Data ...
        </h1>
      </div>
    </v-container>
  </div>
</template>



<script>
import ReportProteinCarb from './ReportProteinCarbTable.vue';
import ReportTable from './ReportTable.vue';

export default {
  components: {
    ReportProteinCarb,
    ReportTable,
  },
  data() {
    return {
      dataLoaded: false,
      items: {
        subscription_count: {
          all: 0,
          active: 0,
          stop: 0
        },
        report_protein_carb: [],
        protein_carb_out_of_kitchen: {
          protein : 0,
          carb : 0
        },
        groups: [],
        groups_out_of_kitchen: [],
        package_details: [],
      },
    };
  },
  created() {
    this.initReport();
  },
  methods: {

    initReport() {
      let date = localStorage.getItem("quantiy_date_export") || moment().format("YYYY-MM-DD");

      this.$store
          .dispatch(`report/exportQuantityCookingDaily`, date)
          .then((res) => {
            this.items = res.data;
            this.dataLoaded = true;
          })
          .catch((error) => {
            console.log(error)
            // this.notifyError("Error", error);
          });
    },
    print() {
      window.print();
    },

    exportTable() {
      const table = this.$refs.exportTable;
      const tableHtml = `
      <html lang="ar" dir="rtl">
        <head>
          <meta charset="UTF-8">
          <style>
            table {
              border-collapse: collapse;
              width: 100%;
              font-size: 16px;
            }
            th, td {
              border: 1px solid #000;
              text-align: center;
              padding: 10px 18px;
              vertical-align: middle;
            }
            th {
              background: #ecf0df;
              color: #000;
              font-weight: bold;
            }
            td {
              background: #dfd9c4;
              color: #000;
            }
          </style>
        </head>
        <body>${table.outerHTML}</body>
      </html>
    `;
      const blob = new Blob([tableHtml], { type: 'application/vnd.ms-excel' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'quantity_report.xls';
      link.click();
    }

  },
};
</script>

<style lang="scss">
.quantity_report {
  .table-container {
    overflow-x: auto;
    max-width: 100%;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    font-size: 16px;
  }

  th,
  td {
    border: 1px solid #000;
    text-align: center;
    padding: 10px 18px;
    vertical-align: middle;
  }

  th {
    background: #ecf0df;
    color: #000;
    font-weight: bold;
  }

  td {
    background: #dfd9c4;
    color: #000;
  }
}

/* Center the loader */
.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
</style>
