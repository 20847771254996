<template>

  <table>
    <tr>
      <td colspan="3" :style="headerStyle">{{ divisionTitle }}</td>
    </tr>
    <tr>
      <td colspan="3" :style="subHeaderStyle">البروتينات (Proteins) </td>
    </tr>
    <tr>
      <td :style="cellHeaderStyle">وجبة البروتين   (Protein Meal) </td>
      <td :style="cellHeaderStyle">العدد (Count) </td>
      <td :style="cellHeaderStyle">مجموع البروتين  (Protein Total)  </td>
    </tr>
    <template v-if="proteins && proteins.length > 0">
      <tr v-for="(prot, index) in proteins" :key="'prot_' + index">
        <td :style="cellStyle">
          {{ prot.recipie_protein_name }}<br />
          {{ prot.recipie_protein_name_en }}
        </td>
        <td :style="cellStyle">{{ prot.protein_count }}</td>
        <td :style="cellStyle">{{ prot.sum_protein_cooking_dailies }}</td>
      </tr>
    </template>
    <tr v-else>
      <td :style="cellStyle" colspan="3">لا توجد بيانات</td>
    </tr>
    <tr>
      <td colspan="3" :style="subHeaderStyle">الكربوهيدرات  (Carbohydrates)</td>
    </tr>
    <tr>
      <td :style="cellHeaderStyle">وجبة الكارب   (Carb Meal) </td>
      <td :style="cellHeaderStyle">العدد (Count) </td>
      <td :style="cellHeaderStyle">مجموع الكارب  (Carb Total)  </td>
    </tr>
    <template v-if="carbs && carbs.length > 0">
      <tr v-for="(carb, index) in carbs" :key="'carb_' + index">
        <td :style="cellStyle">
          {{ carb.recipie_carb_name }}<br />
          {{ carb.recipie_carb_name_en }}
        </td>
        <td :style="cellStyle">{{ carb.carb_count }}</td>
        <td :style="cellStyle">{{ carb.sum_carb_cooking_dailies }}</td>
      </tr>
    </template>
    <tr v-else>
      <td :style="cellStyle" colspan="3">لا توجد بيانات</td>
    </tr>
  </table>
</template>

<script>
export default {
  props: {
    divisionTitle: {
      type: String,
      required: true,
    },
    proteins: {
      type: Array,
      required: true,
    },
    carbs: {
      type: Array,
      required: true,
    },
  },
  computed: {
    headerStyle() {
      return {
        background: '#96b3d6',
        color: '#000',
        fontWeight: 'bold',
        border: '1px solid #000',
        textAlign: 'center',
        padding: '10px 18px',
        verticalAlign: 'middle',
      };
    },
    subHeaderStyle() {
      return {
        background: '#ecf0df',
        color: '#000',
        fontWeight: 'bold',
        border: '1px solid #000',
        textAlign: 'center',
        padding: '10px 18px',
        verticalAlign: 'middle',
      };
    },
    cellHeaderStyle() {
      return {
        background: '#dfd9c4',
        color: '#000',
        fontWeight: 'bold',
        border: '1px solid #000',
        textAlign: 'center',
        padding: '10px 18px',
        verticalAlign: 'middle',
      };
    },
    cellStyle() {
      return {
        border: '1px solid #000',
        textAlign: 'center',
        padding: '10px 18px',
        verticalAlign: 'middle',
      };
    },
  },
};
</script>

<style scoped>
/* Add any specific styles for your component here */
</style>
