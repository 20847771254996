import { render, staticRenderFns } from "./ReportProteinCarbTable.vue?vue&type=template&id=1a3683bf&scoped=true&"
import script from "./ReportProteinCarbTable.vue?vue&type=script&lang=js&"
export * from "./ReportProteinCarbTable.vue?vue&type=script&lang=js&"
import style0 from "./ReportProteinCarbTable.vue?vue&type=style&index=0&id=1a3683bf&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a3683bf",
  null
  
)

export default component.exports