<template>
  <div>
    <h2 :style="headerStyle">{{ title }}</h2>
    <table v-if="data && data.length">
      <!-- Table header -->
      <thead>
      <tr>
        <th :style="subHeaderStyle" v-for="header in headers" :key="header">{{ header }}</th>
      </tr>
      </thead>
      <!-- Table body -->
      <tbody>
      <tr v-for="(item, index) in data" :key="index">
        <td v-for="(value, key) in item" :key="key" v-html="renderValue(value, key)"></td>
      </tr>
      </tbody>
    </table>
    <p v-else>No data available</p>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    headers: {
      type: Array,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
  },

  computed: {
    headerStyle() {
      return {
        background: '#96b3d6',
        color: '#000',
        fontWeight: 'bold',
        border: '1px solid #000',
        textAlign: 'center',
        padding: '10px 18px',
        verticalAlign: 'middle',
      };
    },
    subHeaderStyle() {
      return {
        background: '#ecf0df',
        color: '#000',
        fontWeight: 'bold',
        border: '1px solid #000',
        textAlign: 'center',
        padding: '10px 18px',
        verticalAlign: 'middle',
      };
    },
    cellHeaderStyle() {
      return {
        background: '#dfd9c4',
        color: '#000',
        fontWeight: 'bold',
        border: '1px solid #000',
        textAlign: 'center',
        padding: '10px 18px',
        verticalAlign: 'middle',
      };
    },
    cellStyle() {
      return {
        border: '1px solid #000',
        textAlign: 'center',
        padding: '10px 18px',
        verticalAlign: 'middle',
      };
    },
  },
  methods: {
    renderValue(value, key) {
      // Check if key is 'group_name' and add a new line
      if (key === 'group_name' || key === 'recipie_name') {
        return value.replace(/\n/g, '<br>');
      }
      return value;
    },
  },
};
</script>

<style scoped>
/* Add any specific styles for your component here */
</style>
